// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalogo-[id]-js": () => import("./../../../src/pages/catalogo/[id].js" /* webpackChunkName: "component---src-pages-catalogo-[id]-js" */),
  "component---src-pages-contactanos-js": () => import("./../../../src/pages/contactanos.js" /* webpackChunkName: "component---src-pages-contactanos-js" */),
  "component---src-pages-en-proceso-js": () => import("./../../../src/pages/en-proceso.js" /* webpackChunkName: "component---src-pages-en-proceso-js" */),
  "component---src-pages-enterprises-js": () => import("./../../../src/pages/enterprises.js" /* webpackChunkName: "component---src-pages-enterprises-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mantenimiento-productos-js": () => import("./../../../src/pages/mantenimiento-productos.js" /* webpackChunkName: "component---src-pages-mantenimiento-productos-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-precios-js": () => import("./../../../src/pages/precios.js" /* webpackChunkName: "component---src-pages-precios-js" */),
  "component---src-pages-tarjeta-de-presentacion-js": () => import("./../../../src/pages/tarjeta-de-presentacion.js" /* webpackChunkName: "component---src-pages-tarjeta-de-presentacion-js" */),
  "component---src-pages-tarjetas-[id]-js": () => import("./../../../src/pages/tarjetas/[id].js" /* webpackChunkName: "component---src-pages-tarjetas-[id]-js" */),
  "component---src-pages-users-js": () => import("./../../../src/pages/users.js" /* webpackChunkName: "component---src-pages-users-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

